require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');

const handleClick = (element, callback) => {
  element.addEventListener('click', callback);

  if (element.tagName !== 'BUTTON') {
    element.addEventListener('keypress', (e) => {
      const key = e.which || e.keyCode || 0;

      if (key === 13) {
        callback(e);
      }
    });
  }
};

const getHash = () => {
  return window.location.hash.substr(1);
};

const setActiveByCategory = () => {
  const hash = getHash();
  const navItems = document.querySelectorAll('.nav__item[data-category]');

  for (const item of navItems) {
    item.classList.remove('nav__item--active');

    if (item.getAttribute('data-category') === hash) {
      item.classList.add('nav__item--active');
    }
  }
};

const initNav = () => {
  const toggleButton = document.querySelector('.sidebar__toggle');
  const sidebarNav = document.querySelector('.sidebar__nav');
  const categoryItems = document.querySelectorAll('[data-category]');

  setActiveByCategory();

  if (toggleButton) {
    handleClick(toggleButton, () => {
      const nav = sidebarNav.querySelector('.nav');

      sidebarNav.style.maxHeight = parseInt(sidebarNav.style.maxHeight) > 0 ? 0 : `${nav.scrollHeight}px`;
      sidebarNav.classList.toggle('sidebar__nav--open');
    });

    for (const item of categoryItems) {
      handleClick(item, () => {
        sidebarNav.style.maxHeight = 0;
        sidebarNav.classList.remove('sidebar__nav--open');
      });
    }
  }

  const navItems = document.querySelectorAll('.nav__item');

  for (const item of navItems) {
    const subnav = item.querySelector('.nav');

    if (subnav) {
      if (item.classList.contains('nav__item--open')) {
        subnav.style.maxHeight = `${subnav.scrollHeight}px`;
      }

      handleClick(item.querySelector('a'), (e) => {
        if (!location.href.includes(e.target.getAttribute('href'))) {
          e.preventDefault();

          const sidebarNavMaxHeight = parseInt(sidebarNav.style.maxHeight);
          const expanded = parseInt(subnav.style.maxHeight) > 0;

          if (expanded) {
            subnav.style.maxHeight = 0;

            sidebarNav.style.maxHeight = sidebarNavMaxHeight > 0
              ? `${sidebarNavMaxHeight - subnav.scrollHeight}px`
              : sidebarNav.style.maxHeight;

          } else {
            subnav.style.maxHeight = `${subnav.scrollHeight}px`;

            sidebarNav.style.maxHeight = sidebarNavMaxHeight > 0
              ? `${sidebarNavMaxHeight + subnav.scrollHeight}px`
              : sidebarNav.style.maxHeight;
          }

          item.classList.toggle('nav__item--open');
        }
      });
    }
  }
};

const initVideo = () => {
  const videos = document.querySelectorAll('video');

  if (videos.length === 0) {
    return;
  }

  window.addEventListener('scroll', (e) => {
    for (let i = 0; i <= videos.length - 1; i++) {
      const viewTop = window.pageYOffset || document.documentElement.scrollTop;
      const viewBottom = viewTop + window.innerHeight;

      const videoTop = videos[i].offsetTop;
      const videoBottom = videoTop + videos[i].offsetHeight;

      if (videoBottom <= viewBottom && videoTop >= viewTop) {
        videos[i].play();
      } else {
        videos[i].pause();
      }
    }
  });
};

const initProjects = () => {
  const hash = getHash();
  const projects = document.querySelectorAll('[data-categories]');

  for (const project of projects) {
    const url = project.querySelector('a');
    const categories = project.getAttribute('data-categories').split(',');

    url.setAttribute('href', url.getAttribute('href').split('#')[0]);
    project.classList.remove('-hidden');

    if (!hash) {
      continue;
    }

    if (!categories.some((category) => category === hash)) {
      project.classList.add('-hidden');
    } else {
      url.setAttribute('href', `${url.getAttribute('href')}#${hash}`);
    }
  }
};

const initProjectDetails = () => {
  const hash = getHash();
  const projectCloseAnchor = document.querySelector('.project-details__close');

  if (projectCloseAnchor && hash) {
    projectCloseAnchor.setAttribute('href', `${projectCloseAnchor.getAttribute('href')}#${hash}`);
  }
};

document.addEventListener('turbolinks:load', () => {
  initNav();
  initVideo();
  initProjects();
  initProjectDetails();

  window.addEventListener('hashchange', () => {
    setActiveByCategory();
    initProjects();
  }, false);
});